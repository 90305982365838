<template>
  <div id="master-container" class="shadow">
    <div id="edit-form">
      <h1 class="cont"><i class="fas fa-envelope-open"></i> Contact Us</h1>
      <p>
        Please get in touch with us for any and all support requests by filling
        in the form below and we will get back to you within 24 hours.
      </p>
      <form class="shadow" style="margin-top: 1rem">
        <div>
          <label for="Email">Your Email Address</label>
          <input
            class="email"
            type="text"
            name="Email"
            v-model="emailAddress"
            placeholder="Enter your email address..."
          />
        </div>
        <div>
          <label for="Email">Your Message</label>
          <textarea
            class="msg"
            name="Message"
            v-model="supportMessage"
            placeholder="Enter your request here..."
          ></textarea>
        </div>
        <div class="btn">
          <button
            id="save-monitor"
            @click.prevent="sendMessage"
            :disabled="isSending || sendComplete"
          >
            <i class="fas fa-spinner fa-pulse" v-if="isSending"></i>
            <i class="fas fa-check" v-if="sendComplete"></i>
            <i class="fas fa-envelope" v-else></i>
            {{ buttonText }}
          </button>

          <div v-if="sendComplete" style="margin-top: 1rem">
            <p>
              Your message has been sent to the support staff. Someone will be
              in touch with you within the next 24 hours.
            </p>
            <p>
              Alternatively you may get in touch with us at the following email
              address:
            </p>
            <p>
              <a
                style="color: blue; font-weight: bold"
                href="mailto:changemon.com@gmail.com"
                >changemon.com@gmail.com</a
              >
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API } from "../constants.js";

export default {
  props: ["user"],

  methods: {
    sendMessage() {
      if (!this.emailAddress || !this.supportMessage || !this.emailIsValid) {
        this.$alertify.alert(
          "Error",
          "Please enter a valid email address and a message in order to send an enquiry!"
        );
        return;
      }

      this.isSending = true;
      axios
        .post(
          API + "send-support-request",
          "Email: " + this.emailAddress + "\n\n Message: " + this.supportMessage
        )
        .then(() => {
          this.isSending = false;
          this.sendComplete = true;
        })
        .catch((err) => {
          this.$alertify.alert(
            "Error",
            "Sorry! Something went worng...<p>Please try again...</p> HINT: " +
              err.message
          );
          this.isSending = false;
          this.sendComplete = false;
        });
    },
  },

  computed: {
    buttonText() {
      if (this.isSending) {
        return "Sending Message...";
      }
      if (this.sendComplete) {
        return "Message Sent!";
      }
      return "Send Message";
    },
    emailIsValid() {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (re.test(this.emailAddress)) {
        return true;
      }

      return false;
    },
  },

  data() {
    return {
      emailAddress: "",
      supportMessage: "",
      isSending: false,
      sendComplete: false,
    };
  },

  created() {
    if (this.user) {
      this.emailAddress = this.user.email;
    }
  },
};
</script>

<style>
.email {
  width: 250px !important;
}

.msg {
  border: 1px solid rgb(199, 199, 199);
  background-color: rgb(250, 250, 250);
  border-radius: 0.5rem;
  padding: 0.8rem;
  display: block;
  width: 90%;
  min-width: 300px;
  min-height: 300px;
}

h1.cont i {
  color: rgb(33, 102, 155);
}
</style>
