<template>
  <div id="edit-form" class="shadow">
    <h1 class="monedit">
      <i v-if="!editMonitorMode" class="fas fa-file-alt"></i>
      <i v-if="editMonitorMode" class="fas fa-pen-square"></i>
      {{ editMonitorMode ? "Edit" : "Create" }} Monitor
    </h1>
    <div id="MSG" v-if="formData.MSG">
      <div v-html="formData.MSG"></div>
    </div>
    <form class="shadow">
      <div>
        <label for="Title">Monitor Name</label>
        <input
          type="text"
          name="Title"
          :class="{ error: errors.Title }"
          v-model="formData.Title"
          placeholder="Enter a name for this monitor to help you identify it..."
        />
        <span class="error-text">{{ errors.Title }}</span>
      </div>

      <div>
        <label for="URL">Website Address</label>
        <input
          ref="url"
          id="URL"
          type="text"
          name="URL"
          :class="{ error: errors.URL }"
          v-model="formData.URL"
          placeholder="Enter the URL of the page you want to monitor..."
        />
        <span class="error-text">{{ errors.URL }}</span>
      </div>

      <div>
        <label for="Type">Notification Type</label>
        <select
          id="Type"
          name="Type"
          :class="{ error: errors.Type }"
          v-model="formData.Type"
          @change="changePlaceholderText"
        >
          <option v-for="t in formData.TypeList" :key="t.Id" :value="t.Id">
            {{ t.Text }}
          </option>
        </select>
        <span class="error-text">{{ errors.Type }}</span>
      </div>

      <div>
        <label for="TypeValue">Xpath/Words/Phrases</label>
        <input
          ref="tval"
          id="TypeValue"
          type="text"
          name="TypeValue"
          :disabled="!enableActions"
          @click="launchElementPicker"
          :class="{ error: errors.TypeValue }"
          v-model="formData.TypeValue"
          :placeholder="placeHolderTxt"
        />
        <span class="error-text">{{ errors.TypeValue }}</span>
      </div>

      <div>
        <label for="Interval">Checking Frequency</label>
        <select
          name="Interval"
          :class="{ error: errors.Interval }"
          v-model="formData.Interval"
        >
          <option v-for="i in formData.IntervalList" :key="i.Id" :value="i.Id">
            {{ i.Text }}
          </option>
        </select>
        <span class="error-text">{{ errors.Interval }}</span>
      </div>

      <div id="emails">
        <label for="Email">Notification Email Address</label>
        <input
          type="text"
          name="Email"
          :class="{ error: errors.Email }"
          v-model="formData.Email"
          @keyup="checkEmailMatch"
          :disabled="user"
          placeholder="Enter an email address to get notified on..."
        />
        <span class="error-text">{{ errors.Email }}</span>

        <input
          v-show="emailsMismatched"
          type="text"
          name="EmailConfirm"
          :class="{ error: emailsMismatched }"
          @keyup="checkEmailMatch"
          v-model="formData.EmailConfirm"
          @paste="preventPaste"
          style="margin-top: 1rem"
          placeholder="Re-enter your email address..."
        />
        <span class="error-text">{{ errors.EmailConfirm }}</span>
        <span class="error-text" v-if="emailsMismatched"
          >Email addresses don't seem to match...</span
        >
      </div>

      <div id="stats" v-if="this.formData.LastUpdated">
        <span
          >Checked on {{ this.formData.LastUpdated | ToLocalDate }} in
          {{ this.formData.TimeTakenForCheck }} seconds.</span
        >
      </div>

      <div id="actions" v-if="enableActions">
        <button
          id="save-monitor"
          @click.prevent="saveMonitor"
          :disabled="emailsMismatched"
        >
          <i class="fas fa-save"></i>Save
        </button>
        <button
          id="disable-monitor"
          v-if="formData.IsEnabled && !formData.MonitorID == 0"
          @click.prevent="disableMonitor"
        >
          <i class="fas fa-ban"></i>Disable
        </button>
        <button
          id="delete-monitor"
          v-if="!formData.MonitorID == 0"
          @click.prevent="deleteMonitor"
        >
          <i class="fas fa-trash-alt"></i>Delete
        </button>
      </div>

      <spinner v-if="!enableActions" />

      <div id="XPathResult" v-if="formData.XPathResult">
        <h2>Monitored Content:</h2>
        <div id="result" v-html="formData.XPathResult" />
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { API } from "../constants.js";
import Spinner from "./Spinner";
import { State } from "../state.js";

export default {
  name: "EditMonitor",

  props: ["user"],

  components: { Spinner },

  data() {
    return {
      emailsMismatched: false,
      editMonitorMode: false,
      enableActions: false,
      formData: {},
      errors: {},
      placeHolderTxt: "Select a notification type on the left first...",
    };
  },

  created() {
    if (this.$route.name == "home") {
      this.formData.MonitorID = 0;
    }

    if (this.$route.name == "monitor") {
      this.formData.MonitorID = this.$route.params.id;
      this.formData.Key = this.$route.params.key;
      this.formData.MonitorID == 0
        ? (this.editMonitorMode = false)
        : (this.editMonitorMode = true);
    }

    if (this.user) {
      this.formData.Email = this.user.email;
    }

    this.postData("monitor/load");
  },

  methods: {
    changePlaceholderText() {
      var sel = this.formData.Type;
      if (sel == 0) {
        this.placeHolderTxt = "Select a notification type on the left first...";
        this.formData.TypeValue = "";
      }
      if (sel == 1 || sel == 2) {
        this.placeHolderTxt = "Enter a word or phrase to look for...";
        this.formData.TypeValue = "";
      }
      if (sel == 3) {
        this.formData.TypeValue = "";
        if (!this.formData.URL) {
          this.placeHolderTxt =
            "Enter a URL above and click here to launch the element picker...";
          this.$refs.url.focus();
        } else {
          this.placeHolderTxt =
            "Click here to launch the element picker or press TAB to manually enter the XPath...";
        }
      }
      if (sel == 4) {
        this.placeHolderTxt =
          "Enter the XPath of the element you want to target...";
        this.formData.TypeValue = "/rss/channel/item[1]/title";
        this.$refs.tval.focus();
      }
    },

    launchElementPicker() {
      var tVal = this.formData.TypeValue;
      if (tVal) {
        return;
      }

      var url = this.formData.URL;
      var type = this.formData.Type;

      if (url && type == 3) {
        this.enableActions = false;
        var w = window.open(
          "",
          "myWindow",
          "width=400,height=250,resizable=yes,scrollbars=no,toolbar=no,menubar=no,location=no"
        );
        w.moveTo(screen.width / 2 - 400 / 2, screen.height / 2 - 250 / 2);
        w.document.body.innerHTML = `<html>
<head>
  <title><< Element Picker >></title>
  <style>
    body {
      background-color: rgb(221, 221, 221);
      font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
      font-size: 1rem;
      text-align: center;
    }
    .lds-ripple {
      position: relative;
      width: 64px;
      height: 64px;
      margin-left: auto;
      margin-right: auto;
    }
    .lds-ripple div {
      position: absolute;
      border: 4px solid #fff;
      opacity: 1;
      border-radius: 50%;
      animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    .lds-ripple div:nth-child(2) {
      animation-delay: -0.5s;
    }
    @keyframes lds-ripple {
      0% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 1;
      }
      100% {
        top: -1px;
        left: -1px;
        width: 58px;
        height: 58px;
        opacity: 0;
      }
    }
  </style>
</head>
<body>
  <div class="lds-ripple">
    <div></div>
    <div></div>
  </div>
  <h1>Fetching your page...</h1>
</body>
</html>`;
        w.onbeforeunload = function () {
          this.enableActions = true;
        };

        axios
          .post(API + "fetch-page", { URL: url })
          .then((res) => {
            if (res.data.errorMsg) {
              this.$alertify.alert(
                "Error",
                "Sorry! Something went worng...<p> Please try again...</p> HINT: " +
                  res.data.errorMsg
              );
              this.enableActions = true;
              w.close();
              return;
            }

            const source = `<html>
<head>
  <title>
    ChangeMon Element Picker
  </title>
  <style>
    body {
      background-image: url('data:image/jpeg;base64,${res.data.image}');
      background-repeat: no-repeat;
      background-position: left top;
      margin: 0;
      padding: 0;
    }
    .changemonhighlight{opacity: 0.5; background-color: yellow !important; box-shadow: 0px 0px 10px 5px red !important;}
  </style>
</head>
<body>
</body>
</html>`;
            w.document.open("text/html", "replace");
            w.document.write(source);
            w.document.close();

            const nodes = JSON.parse(res.data.nodes);
            const div = transformNodes(nodes, null);
            div.setAttribute("id", "bdy");
            w.document.getElementsByTagName("body")[0].appendChild(div);
            w.document.getElementsByTagName("body")[0].style.height =
              nodes.height;
            w.document.getElementsByTagName("body")[0].style.cursor = "pointer";

            w.resizeTo(nodes.width + 50, nodes.height);
            w.moveTo(
              screen.width / 2 - nodes.width / 2,
              screen.height / 2 - nodes.height / 2
            );
            w.focus();

            var isScrolling;
            w.document.addEventListener(
              "scroll",
              () => {
                window.clearTimeout(isScrolling);
                isScrolling = setTimeout(function () {
                  w.document.querySelectorAll("div").forEach((div) => {
                    div.style.top = div.getAttribute("data-top") - w.scrollY;
                  });
                }, 100);
              },
              false
            );

            w.document.getElementById("bdy").addEventListener("click", (e) => {
              e.preventDefault();
              this.enableActions = true;
              let xpath = e.target.getAttribute("data-xpath");
              this.formData.TypeValue = xpath;
              e.stopPropagation();
              if (xpath.includes("/iframe") || xpath.includes("/frame")) {
                w.alert(
                  "This element is an iframe. Please select another element or use the iframe source "
                );
              } else {
                w.close();
                this.$refs.tval.focus();
              }
            });

            w.document.querySelectorAll("div").forEach((div) => {
              div.addEventListener("mouseover", (e) => {
                e.target.classList.add("changemonhighlight");
                e.stopPropagation();
              });
              div.addEventListener("mouseout", (e) => {
                e.target.classList.remove("changemonhighlight");
                e.stopPropagation();
              });
            });

            function transformNodes(node, parent) {
              let div = w.document.createElement("div");
              div.style.cssText = `position:fixed;left:${node.left};top:${node.top};width:${node.width};height:${node.height};`;
              div.setAttribute("data-xpath", node.xpath);
              div.setAttribute("data-top", node.top);

              if (parent) {
                parent.appendChild(div);
              }

              node.children.forEach((child) => {
                transformNodes(child, div);
              });

              if (!parent) {
                return div;
              }
            }
          })
          .catch((err) => {
            this.$alertify.alert(
              "Error",
              "Sorry! Something went worng...<p> Please try again...</p> HINT: " +
                err.response.data.Message
            );
            this.enableActions = true;
            w.close();
          });
      }
    },

    checkEmailMatch() {
      this.formData.Email != this.formData.EmailConfirm && this.formData.Email
        ? (this.emailsMismatched = true)
        : (this.emailsMismatched = false);
    },

    preventPaste(e) {
      e.preventDefault();
    },

    saveMonitor() {
      this.postData("monitor/save");
    },

    postData(EndPoint) {
      var pData = {
        MonitorID: this.formData.MonitorID,
        Title: this.formData.Title,
        URL: this.formData.URL,
        Type: this.formData.Type,
        TypeValue: this.formData.TypeValue,
        Interval: this.formData.Interval,
        Email: this.formData.Email,
        Key: this.formData.Key,
      };

      this.enableActions = false;
      this.errors = {};

      axios
        .post(API + EndPoint, pData)
        .then((res) => {
          this.enableActions = true;
          this.formData = res.data;
          if (this.formData.ValidationErrors) {
            //errors
            this.errors = this.formData.ValidationErrors;
            window.scrollTo(0, 0);

            if (this.errors.Email) {
              if (this.errors.Email === "Subscription quota reached!") {
                this.$alertify.alert(
                  "Error",
                  `Sorry! You have reached the subscription quota limit.</br>
                   Please delete unwanted or duplicate monitors to proceed.</br>
                   Or you may create a new subscription with another email address.`
                );
              }
            }
          } else {
            //success
            this.scrollToResult();
            this.formData.EmailConfirm = this.formData.Email;
            if (this.formData.User) {
              this.$events.emit("set-user", {
                key: this.formData.User.Key,
                email: this.formData.User.Email,
                secret: this.formData.User.Secret,
              });
            }
            if (this.formData.MonitorID != 0 && this.user) {
              window.history.pushState(
                null,
                "ChangeMon",
                `/#/monitor/${this.formData.MonitorID}/${this.formData.Key}`
              );
              this.refreshState();
            }
          }
        })
        .catch((err) => {
          this.$alertify.alert(
            "Error",
            "Sorry! Something went worng...<p> Please try again...</p> HINT: " +
              err.message
          );
          this.enableActions = true;
        });
    },

    refreshState() {
      if (State.ViewData) {
        const mon = {
          DisableReason: this.formData.DisableReason,
          IsEnabled: this.formData.IsEnabled,
          Key: this.formData.Key,
          LastUpdated: this.formData.LastUpdated,
          MonitorID: this.formData.MonitorID,
          Title: this.formData.Title,
          WarnNonExistentElement: this.formData.WarnNonExistentElement,
        };

        const indx = State.ViewData.Monitors.findIndex(
          (m) => m.MonitorID === mon.MonitorID
        );

        if (indx === -1) {
          State.ViewData.Monitors.unshift(mon);
        }

        State.ViewData.Monitors[indx] = mon;
      }
    },

    deleteMonitor() {
      if (confirm("Are you sure you want to delete this monitor?")) {
        this.enableActions = false;
        axios
          .get(
            API +
              `monitor/delete?monID=${this.formData.MonitorID}&key=${this.formData.Key}`
          )
          .then(() => {
            if (State.ViewData) {
              const indx = State.ViewData.Monitors.findIndex(
                (m) => m.MonitorID === this.formData.MonitorID
              );
              if (indx >= 0) {
                State.ViewData.Monitors.splice(indx, 1);
              }
            }
            this.$router.push({ name: "my-monitors" });
          })
          .catch((err) => {
            this.$alertify.alert(
              "Error",
              "Sorry! Something went worng...<p> Please try again...</p> HINT: " +
                err.message
            );
            this.enableActions = true;
          });
      }
    },

    disableMonitor() {
      this.enableActions = false;
      axios
        .get(
          API +
            `monitor/disable?monID=${this.formData.MonitorID}&key=${this.formData.Key}`
        )
        .then(() => {
          this.formData.IsEnabled = false;
          this.formData.DisableReason = 8;
          this.formData.MSG =
            "<p><b>MONITOR DISABLED:</b></p><p>Successfully disabled this monitor. If you would like to enable it again, please hit the SAVE button.</p>";
          this.enableActions = true;
          this.refreshState();
        })
        .catch((err) => {
          this.$alertify.alert(
            "Error",
            "Sorry! Something went worng...<p> Please try again...</p> HINT: " +
              err.message
          );
          this.enableActions = true;
        });
    },

    scrollToResult() {
      var elmnt = document.getElementById("XPathResult");
      if (elmnt) {
        setTimeout(() => {
          elmnt.scrollIntoView();
          window.scrollBy(0, -110);
        }, 500);
      }
    },
  },
};
</script>

<style>
.error {
  border: 1px solid rgb(194, 53, 53) !important;
}

.error-text {
  color: rgb(194, 53, 53);
  font-weight: 600;
  margin-left: 0.8rem;
}

#edit-form {
  background-color: rgba(255, 255, 255, 0.76);
  padding: 1rem;
  border-radius: 7px;
}

#edit-form > form {
  display: flex;
  flex-wrap: wrap;
  border-radius: 7px;
  padding: 1rem;
}

#edit-form > form > div {
  flex: 1 0 98%;
  margin: 0.5rem;
}
#edit-form > form > div > * {
  display: block;
}

#edit-form > form > div > input,
#edit-form > form > div > select {
  border: 1px solid rgb(199, 199, 199);
  background-color: rgb(250, 250, 250);
  border-radius: 0.5rem;
  padding: 0.8rem;
  display: block;
  width: 90%;
}

#edit-form > form > div > label {
  color: #71095a98;
  font-weight: 600;
}

#edit-form > form > div > select {
  width: 250px;
}

#emails input {
  width: 250px !important;
}

#emails > input:last-child {
  margin-top: 0.5rem;
}

#MSG {
  border: 1px solid rgba(177, 177, 177, 0.699);
  background-color: rgb(251, 255, 238);
  padding: 1rem;
  border-radius: 7px;
  margin-bottom: 1rem;
}

#MSG b {
  color: rgb(179, 24, 24);
}

#result {
  border: 1px solid rgba(175, 175, 175, 0.534);
  padding: 1rem;
  background-color: rgb(240, 240, 240);
  font-size: 0.9rem;
  word-break: break-word;
}
#result ins {
  text-decoration: none;
  background-color: rgb(224, 255, 224);
}

#result del {
  background-color: rgb(255, 224, 224);
  text-decoration: line-through;
}

#result a {
  color: #b52323;
}

#actions {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

#actions > button {
  margin: 0 1rem 0 0;
}

#save-monitor {
  border: 1px solid grey;
  background-color: #1d8335;
  padding: 1rem;
  border-radius: 1rem;
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 1.2rem;
}

#save-monitor i {
  margin-right: 0.5rem;
}

#save-monitor:hover {
  background-color: #33af50;
  cursor: pointer;
}

#disable-monitor {
  border: 1px solid grey;
  background-color: #5e5e5e;
  padding: 1rem;
  border-radius: 1rem;
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 1.2rem;
}

#disable-monitor i {
  margin-right: 0.5rem;
}

#disable-monitor:hover {
  background-color: #a0a0a0;
  cursor: pointer;
}

#delete-monitor {
  border: 1px solid grey;
  background-color: #57301e;
  padding: 1rem;
  border-radius: 1rem;
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 1.2rem;
}

#delete-monitor i {
  margin-right: 0.5rem;
}

#delete-monitor:hover {
  background-color: #995e43;
  cursor: pointer;
}

#stats {
  color: #5e5e5e;
  font-size: 0.8rem;
}

h1.monedit i {
  color: rgb(33, 102, 155);
}

#XPathResult > h2 {
  margin: 0;
}
</style>
