<template>
  <div id="app">
    <header-cmp />
    <section id="content">
      <div id="container">
        <transition name="fade" mode="out-in">
          <router-view :user="user" />
        </transition>
      </div>
    </section>
    <footer-cmp />
  </div>
</template>

<script>
import HeaderCmp from "./cmp/Header";
import FooterCmp from "./cmp/Footer";

export default {
  name: "App",

  created() {
    this.$events.listen("set-user", (user) => {
      this.$cookies.set("user", JSON.stringify(user), "365d");
      this.user = user;
    });

    this.$events.listen("remove-user", () => {
      this.$cookies.remove("user");
      this.user = null;
    });

    this.user = this.$cookies.get("user");
  },

  components: {
    HeaderCmp,
    FooterCmp,
  },

  data() {
    return {
      user: {},
    };
  },

  methods: {},
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Nunito|Quattrocento");

ul,
p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

a:link,
a:visited,
a:active {
  color: #fff;
}

a:hover {
  color: #71095a;
}

#disqus_thread a:any-link {
  color: #288ce4 !important;
}

#app {
  display: flex;
  flex-direction: column;
  font-family: "Nunito", sans-serif;
  min-width: 410px;
  border-radius: 10px;
}

#content {
  background-color: rgb(221, 242, 255);
  min-height: 50vh;
  padding: 1rem;
}

.shadow {
  box-shadow: 2px 2px 14px -2px rgba(163, 163, 163, 1);
}

.ajs-header {
  font-size: 2rem;
  color: rgb(202, 0, 0) !important;
  font-family: "Courier New", Courier, monospace;
}

.ajs-content {
  font-size: 1rem;
  color: grey;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.ajs-content p {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

@media screen and (min-width: 450px) {
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1024px) {
  #container {
    max-width: 1024px;
    margin: 0 auto;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
