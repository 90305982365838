<template>
  <div id="master-container" class="shadow" style="padding:1rem">
    <div class="tab">
      <button
        id="defaultOpen"
        class="tablinks"
        @click="openTab($event, 'Help')"
      >
        Help
      </button>
      <button class="tablinks" @click="openTab($event, 'Tips')">Tips</button>
      <button class="tablinks" @click="openTab($event, 'Community')">
        Community
      </button>
    </div>
    <div id="Help" class="tabcontent notes">
      <h1>
        <strong>
          <u>// HELP //</u>
        </strong>
      </h1>
      <p>
        <b>Additions: </b>If you'd like to be notified when a word or phrase is
        added to a web page, please select the first option from the
        notification type dropdown and enter your word or phrase (case
        insensitive) in the next box.
      </p>
      <p>
        <b>Removals: </b>If you'd like to be notified when a word or phrase is
        removed from a web page, please select the second option from the
        notification type dropdown and enter your word or phrase (case
        insensitive) in the next box.
      </p>
      <p>
        <b>Specific Elements: </b>If you'd like to be notified when a specific
        part of a web page changes, please select the third option from the
        notification type dropdown and click inside the next box to launch the
        element picker. A new window will then open. Give it a couple of seconds
        to load your page. You can then hover your mouse pointer over the
        section/element you are interested in and simply click to choose the
        highlighted area shown in yellow and red as your target element. A lot
        more advanced scenarios are possible and the element picker is only
        meant to be a simple visual tool. Check the <b>Tips</b> section and the
        <b>Community</b> sections above to see what's possible. Please get in
        touch with us for any advanced requirments and we will be glad to help
        you create the exact type of monitor you need if it's technically
        possible to do so.
      </p>
      <p>
        <b>RSS/Atom Feeds: </b>If you'd like to monitor an RSS feed for new
        items, select the 4th option from the notification type dropdown. For
        Atom feeds, please change the XPath to
        <b>/feed/entry[1]/title</b>
      </p>

      <h1>
        <strong>
          <u>// EXAMPLES //</u>
        </strong>
      </h1>
      <p>
        You can see a list of example monitors covering a wide variety of use
        cases by<a
          class="samples-link"
          target="_blank"
          href="https://changemon.com/#/my-monitors/changemoncomgmailcom/ne6vxatvy4v70jg1avkdja2"
        >
          clicking here.</a
        >
        <br />
        These are just basic examples of what you can do with ChangeMon. A lot
        more advanced scenarios are possible with customized XPaths. Please
        refer to the <b>Tips</b> and <b>Community</b> section for more info or
        simply get in touch with us using the <b>Contact Us</b> link above and
        we will be more than glad to help you with your specific requirements.
      </p>
    </div>

    <div id="Tips" class="tabcontent notes">
      <h1>
        <strong>
          <u>// TIPS &amp; TRICKS //</u>
        </strong>
      </h1>
      <p>
        <b>Too many emails: </b>If you are getting too many emails, please
        select a less frequently changing element which is at a deeper level or,
        you can simply increase the check interval time to either 'Every day' or
        'Every week'.
      </p>
      <p>
        <b>Multiple terms: </b>If you would like to monitor for the addition or
        removal of multiple terms on a page, you can specify the terms in a
        comma seperated string and append either <b>{any}</b> or <b>{all}</b> to
        the end of the string to tell the system to either match any of the
        terms or all of the terms. For example, to watch a page for the removal
        of both the terms "out of stock" and "preorder now", you can enter the
        string: out of stock , preorder now {all}
      </p>
      <p>
        <b>Change highlighting: </b>If you would like to disable the green and
        red diff style change highlighting, please append <b>{nodiff}</b> to
        your XPath. For example: /html/body {nodiff}<br /><br />
        The default diffing algorithm is character based. If you would like to
        do word based diffing, please append <b>{diffwords}</b> to the XPath.
        Word based diffing is recommended when the result of your XPath is a
        short string of text. Character based diffing is best for a large volume
        of text.
      </p>
      <p>
        <b>Non-existent elements: </b>If you'd like to be notified when an
        element that does not yet exist on the page gets added in the future,
        you can append <b>{allowempty}</b> to the end of your XPath. For ex:
        /html/body/table {allowempty}
      </p>
      <p>
        <b>Ignore non-existent elements: </b> By default, you will be notified
        when an element you are watching disappears from the page. If you don't
        want to be notified of the removal of your target element and only wish
        to be notified about a change to the content of your target element, you
        can append <b>{ignorenonexistent}</b> to your Xpath. For ex:
        /html/body/table {ignorenonexistent}
      </p>
      <p>
        <b>Source code changes: </b> If you'd like to be notified when there's a
        change in the underlying source-code and not just the visible text of
        the content you are monitoring, you can append <b>{watchsource}</b> to
        the end of the XPath. For ex: /html/body/div {watchsource}
      </p>
      <p>
        <b>Sorting elements: </b>If you'd like to sort the matched elements in
        ascending order, you can append <b>{sort}</b> to the end of your XPath.
        For ex: /html/body/table/tr {sort}
      </p>
      <p>
        <b>JSON to XML: </b> You can convert a page that returns a JSON result
        to XML on the fly and use an XPath for selecting desired elements by
        appending <b>{jsontoxml}</b> to the Xpath. For this to work, you must
        create a monitor as a RSS type monitor.
      </p>
      <p>
        <b>Include Links</b>: If you want to monitor a list of links ('a' tags)
        on a page, only the link titles/inner text will be watched by default.
        If you'd like to monitor the link titles as well as the full URL of the
        link, you can append <b>{includelinks}</b> to the end of your Xpath.
        <br />
        For ex: /html/body/div/a {includelinks}
      </p>
      <p>
        <b>Regex Matching: </b> You can filter matched elements further by
        supplying a regex pattern by appending it to the XPath within double
        curly braces like so: <b>{{</b>PATTERN<b>}}</b> For ex: /html/body//h1
        {{ this.emailRegex }} <br />
        The above XPath will select and monitor all h1 tags that contain an
        email address.
      </p>
      <p>
        <b>Posting data: </b>Pages are fetched using "GET" requests by default.
        If you'd like us to fetch a page using a "POST" request by posting some
        data, simply append your data to the end of the URL like so:<br />
        http://website.com/seach<b
          >?postdata=parameter1:value1,parameter2:value2</b
        >
        <br />For example if you want to monitor a UPS tracking page, append the
        post data to the URL like so:<br />http://wwwapps.ups.com/WebTracking/track?<b
          >postdata=trackNums:1234567890,track.x:Track</b
        ><br />If you don't know how to use Chrome Developer Tools to sniff out
        the post parameters in order to construct a working URL for posting
        data, please ask in the Community section or send in a support request
        from the Contact Us page above.
      </p>
      <p>
        <b>Cookie support: </b>You can specify cookies for a particular page by
        appending the cookie name/value pairs to the URL like so:
        http://example.com/myaccount<b>?cookiedata=name1:value1,name2:value2</b>.
        This feature is only supported with non RSS type monitors. Do not use
        with sites that contain sensitive data. This feature is still
        experimental and we cannot guarantee that your cookies will be secure.
        Use at your own risk.
      </p>
      <p>
        <b>Rendering delay: </b>If a certain page does not load all of it's
        contents in time when you use the element picker or you are getting
        false positive notification emails, you may give our rendering engine
        more time to finish loading the page by adding a query string to the URL
        like so: http://example.com/<b>?renderdelay=5</b>. The default rendering
        time is 3 seconds. You may specify a value anywhere from 1 to 10.
      </p>
      <p>
        <b>Rendering as mobile device: </b>
        You can force rendering the mobile view of a web page by appending a
        query string to the url like so: http://example.com/<b
          >?rendermobile=yes</b
        >
      </p>
      <p>
        <b>Blocked websites: </b>If ChangeMon has been blocked by your desired
        website from accessing their servers, please let us know using the
        Contact Us page and we will investigate further on how to get around
        their blocks.
      </p>
      <p>
        <b>Hidden elements: </b>If the element you are interested in cannot be
        visually selected by the element picker due to it being hidden from
        view, you can do the following to setup a monitor for that page element:
      </p>
      <p>
        <ol>
          <li>Open your desired page in a new Google Chrome tab</li>
          <li>Right click on the element you are interested in. Then click "Inspect"</li>
          <li>Right click on the highlighted blue line. Then click "Copy > Copy XPath"</li>
          <li>Paste the XPath you copied into the XPath field on the monitor creation form.</li>
          <li>Fill up the rest of the fields as usual and click "SAVE". That's it.</li>
        </ol>
      </p>
    </div>

    <div id="Community" class="tabcontent notes">
      <h1>
        <strong>
          <u>// COMMUNITY FEEDBACK //</u>
        </strong>
      </h1>
      <p>
        Do you need help or have something to say to the team?<br />
        Leave a comment below and we will get back to you as soon as we can.<br />
        If you need immediate assistance, please get in touch with us using the
        "Contact Us" page above.
      </p>
      <div id="disqus_thread">
        <vue-disqus
          shortname="changemon"
          identifier="front-page"
          title="ChangeMon.Com"
          url="https://changemon.com/"
        >
        </vue-disqus>
      </div>
    </div>

    <div class="notes"></div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.getElementById("defaultOpen").click();
  },
  data() {
    return {
      emailRegex: "{{^S+@S+$}}"
    };
  },
  methods: {
    openTab(evt, tabName) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }
      document.getElementById(tabName).style.display = "block";
      if (evt) {
        evt.currentTarget.className += " active";
      }
    }
  }
};
</script>

<style>
.notes p {
  padding: 1rem;
  color: rgba(0, 0, 0, 0.726);
}

#disqus_thread {
  margin: 0 1rem;
}

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 1rem;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.tabcontent {
  animation: fadeEffect 1s;
  /* Fading effect takes 1 second */
}

a.samples-link:any-link {
  color: rgb(17, 48, 151);
}

/* Go from zero to full opacity */
@keyframes fadeEffect {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
