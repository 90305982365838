<template>
  <div>
    <p class="intro shadow" v-if="!showEditMonitor">
      ChangeMon exists so that you never have to check a web page manually for a
      piece of information you are interested in. So, forget about that F5
      button and setup a monitor and tell ChangeMon exactly how you want that
      page monitored. It will send you an email notification as soon as that
      piece of information changes. Here are a few highlights of what ChangeMon
      can do...
    </p>
    <div id="features" v-if="!showEditMonitor">
      <figure class="feature">
        <i class="fab fa-html5"></i>
        <span>Supports HTML web pages</span>
      </figure>
      <figure class="feature">
        <i class="fas fa-rss"></i>
        <span class="feature-text">Supports RSS &amp; Atom feeds</span>
      </figure>
      <figure class="feature">
        <i class="fab fa-js"></i>
        <span class="feature-text">JavaScript rendering</span>
      </figure>
      <figure class="feature">
        <i class="fab fa-wpforms"></i>
        <span class="feature-text"
          >Monitor additions &amp; removals of specific words or phrases</span
        >
      </figure>
      <figure class="feature">
        <i class="fas fa-chart-pie"></i>
        <span class="feature-text"
          >Monitor only the specific parts of a web page you need</span
        >
      </figure>
      <figure class="feature">
        <i class="far fa-eye"></i>
        <span class="feature-text"
          >Visual element picker for selecting interested parts of pages</span
        >
      </figure>
      <figure class="feature">
        <i class="fas fa-hourglass-end"></i>
        <span class="feature-text"
          >Checking frequencies range from 15 minutes up to 1 month</span
        >
      </figure>
      <figure class="feature">
        <i class="fa fa-plug"></i>
        <span class="feature-text"
          >Customize XPath queries for advanced monitoring scenarios</span
        >
      </figure>
      <figure class="feature">
        <i class="fas fa-highlighter"></i>
        <span class="feature-text"
          >Diff style change highlighting both on web and email
          notifications</span
        >
      </figure>
      <figure class="feature">
        <i class="fab fa-java"></i>
        <span class="feature-text">Support for monitoring JSON results</span>
      </figure>
      <figure class="feature">
        <i class="fas fa-cookie-bite"></i>
        <span class="feature-text">Support for Cookies (experimental)</span>
      </figure>
      <figure class="feature">
        <i class="far fa-envelope-open"></i>
        <span class="feature-text">Supports both POST &amp; GET requests</span>
      </figure>
    </div>
    <div id="pricing" v-if="!showEditMonitor" class="shadow">
      <div class="flx-itm">
        <div id="price" class="shadow">
          <div id="amount">$ 9.95</div>
          Per Month
        </div>
      </div>
      <div class="flx-itm price-features">
        <ul>
          <li><i class="fas fa-check"></i>No account creation needed</li>
          <li><i class="fas fa-check"></i>Create up to 1000 monitors</li>
          <li><i class="fas fa-check"></i>Free 7 day trial period</li>
          <li><i class="fas fa-check"></i>Support mail answered in 24 hours</li>
        </ul>
      </div>
      <div class="flx-itm">
        <img src="../assets/paypal.png" alt="" id="paypal-logo" />
      </div>
      <div class="flx-itm">
        <button id="create-monitor" @click.prevent="showForm">
          <i class="fas fa-plus-circle"></i>Create Monitor
        </button>
      </div>
    </div>
    <edit-monitor v-if="showEditMonitor" :user="user" />
  </div>
</template>

<script>
import EditMonitor from "../cmp/EditMonitor";

export default {
  name: "Home",

  props: ["user"],

  components: { EditMonitor },

  data() {
    return {
      showEditMonitor: false,
    };
  },

  methods: {
    showForm() {
      this.showEditMonitor = true;
    },
  },
};
</script>

<style>
.intro {
  background-color: rgba(255, 255, 255, 0.76);
  padding: 1rem;
}
#features {
  padding: 0;
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
}
.feature {
  flex: 1 0 200px;
  background-color: rgb(225, 225, 225);
  margin: 0.1rem;
  padding: 1rem;
  text-align: center;
}
.feature i {
  font-size: 2.5rem;
  display: block;
  text-align: center;
  color: #71095a;
}

.feature span {
  font-size: 0.9rem;
}

#pricing {
  background-color: rgba(255, 255, 255, 0.76);
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 7px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

#heading {
  font-weight: 900;
}
.price-features {
  flex-shrink: 0;
}
.price-features ul {
  list-style: none;
  padding: 0.5rem;
}

.price-features i {
  font-size: 1rem;
  margin-right: 0.5rem;
  color: rgb(35, 155, 103);
}

#price {
  border: 1px dashed rgb(168, 168, 168);
  border-radius: 50px;
  padding: 1rem;
  display: inline-block;
  background-color: rgb(242, 253, 228);
  text-align: center;
}

#amount {
  font-size: 2rem;
  font-weight: 900;
}

#paypal-logo {
  width: 250px;
}

#create-monitor {
  border: 1px solid grey;
  background-color: #1d8335;
  padding: 1rem;
  border-radius: 1rem;
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 1.2rem;
}

#create-monitor i {
  margin-right: 1rem;
}

#create-monitor:hover {
  background-color: #33af50;
  cursor: pointer;
}
</style>
