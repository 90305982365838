<template>
  <div>
    <edit-monitor :user="user" />
  </div>
</template>

<script>
import EditMonitor from "../cmp/EditMonitor";

export default {
  name: "Monitor",

  props: ["user"],

  components: {
    EditMonitor,
  },
};
</script>

<style></style>
