<template>
  <div id="master-container" class="shadow">
    <div id="edit-form">
      <h1 class="chng"><i class="fas fa-at"></i> Change Email</h1>
      <p>
        You can change the email address associated with your monitors by
        filling in the fields below.
      </p>
      <p style="font-size: 0.85rem">
        <strong>Note: </strong>
        If you were using + and . variations of your email address, they will
        all be merged in to one email address after changing your email.
      </p>
      <form class="shadow" style="margin-top: 1rem">
        <div>
          <label for="Email">Current Email Address</label>
          <input
            class="email"
            type="text"
            name="Email"
            :value="user.email"
            :disabled="true"
          />
        </div>
        <div>
          <label for="NewEmail">New Email Address</label>
          <input
            class="email"
            type="text"
            name="NewEmail"
            v-model="NewEmail"
            placeholder="Enter new email address..."
          />
        </div>
        <div>
          <label for="NewEmailVerify">Re-enter Email</label>
          <input
            class="email"
            type="text"
            name="NewEmailVerify"
            v-model="NewEmailVerify"
            placeholder="Re-enter new email..."
          />
        </div>
        <spinner v-if="inProgress" />
        <div
          class="btn"
          v-if="
            emailIsValid &&
            NewEmailVerify == NewEmail &&
            NewEmail != user.email &&
            !inProgress
          "
        >
          <button id="save-monitor" @click.prevent="ChangeEmail">
            <i class="fas fa-envelope"></i>
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API } from "../constants.js";
import Spinner from "../cmp/Spinner";

export default {
  components: { Spinner },

  props: ["user"],

  data() {
    return {
      NewEmail: "",
      NewEmailVerify: "",
      inProgress: false,
    };
  },

  methods: {
    ChangeEmail() {
      this.inProgress = true;
      axios
        .post(API + "change-email", {
          UserKey: this.user.key,
          UserSecret: this.user.secret,
          NewEmailAddress: this.NewEmail,
        })
        .then((res) => {
          if (res.data == "Success!") {
            this.$alertify.alert(
              "<span style='color:green'>Success!</span>",
              "Email address changed... You have now been logged out. <p>Please log back in by re-authenticating...</p>"
            );
            this.$events.emit("remove-user");
            this.$router.push({ name: "my-monitors" });
          }
        })
        .catch((err) => {
          this.$alertify.alert(
            "Error",
            "Sorry! Something went worng...<p> Please try again...</p> HINT: " +
              err.response.data.Message
          );
          this.inProgress = false;
        });
    },
  },

  computed: {
    emailIsValid() {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (re.test(this.NewEmail)) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style>
h1.chng i {
  color: rgb(33, 102, 155);
}
</style>
