import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueCookies from "vue-cookies";
import VueEvents from "vue-events";
import Moment from "moment";
import VueDisqus from "vue-disqus";
import VueAlertify from "vue-alertify";

Vue.use(VueCookies)
  .use(VueEvents)
  .use(VueDisqus)
  .use(VueAlertify).config.productionTip = false;

Vue.filter("ToLocalDate", function (value) {
  if (!value) return "";
  return Moment.utc(value.toString()).local().format("lll");
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
