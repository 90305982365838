<template>
  <div id="master-container" class="shadow">
    <div id="payment-wrapper">
      <div v-if="!paymentComplete" class="payment-inner">
        <i class="fas fa-spinner fa-pulse loader"></i>
        <h1>Processing your payment...</h1>
      </div>

      <div v-if="paymentComplete" class="payment-inner">
        <i class="far fa-check-circle complete"></i>
        <h1>Payment Successful, Thank You!</h1>
        <router-link to="/my-monitors" class="lnk-proceeed"
          >CLICK HERE TO PROCEED >></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API } from "../constants.js";

export default {
  data() {
    return {
      paymentComplete: false,
    };
  },

  created() {
    var payerID = this.$route.query.PayerID;
    var paymentID = this.$route.query.paymentId;
    var user = this.$route.query.user;

    if (!payerID || !paymentID || !user) {
      this.$alertify.alert(
        "Error",
        "Sorry! Something went wrong... <p>Please try again...</p> HINT: " +
          "Required parameters missing!!!"
      );
      return;
    }

    axios
      .get(
        API +
          "paypal/execute-payment" +
          `?payerID=${payerID}&paymentID=${paymentID}&user=${user}`
      )
      .then((res) => {
        if (res.data == "Payment completed successfully") {
          //success
          this.paymentComplete = true;
        } else {
          this.$alertify.alert(
            "Error",
            "Sorry! Something went wrong...<p>Please try again...</p> HINT: " +
              res.data
          );
          this.$router.push({ name: "my-monitors" });
        }
      })
      .catch((err) => {
        this.$alertify.alert(
          "Error",
          "Sorry! Something went wrong...<p>Please try again...</p> HINT: " +
            err.response.data.Message
        );
      });
  },
};
</script>

<style>
.loader {
  font-size: 4rem;
  color: #71095a;
}
.complete {
  font-size: 6rem;
  color: #009b08;
}

.lnk-proceeed:any-link {
  color: #71095a;
  font-weight: bold;
}

#payment-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.payment-inner {
  text-align: center;
}
</style>
