<template>
  <footer>
    <div>
      <p>
        <strong> TERMS &amp; CONDITIONS: </strong>
      </p>
      <p style="font-size: 10px">
        YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICE IS AT
        YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE"
        BASIS. CHANGEMON EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
        EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES
        OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
        NON-INFRINGEMENT. CHANGEMON MAKES NO WARRANTY THAT (i) THE SERVICE WILL
        MEET YOUR REQUIREMENTS, (ii) THE SERVICE WILL BE UNINTERRUPTED, TIMELY,
        SECURE, OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE
        USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, (iv) THE QUALITY OF ANY
        PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED
        BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS, AND (v) ANY
        ERRORS IN THE SOFTWARE WILL BE CORRECTED. ANY MATERIAL DOWNLOADED OR
        OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DONE AT YOUR OWN
        DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY
        DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE
        DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR INFORMATION, WHETHER ORAL OR
        WRITTEN, OBTAINED BY YOU FROM CHANGEMON OR THROUGH OR FROM THE SERVICE
        SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TOS. YOU EXPRESSLY
        UNDERSTAND AND AGREE THAT CHANGEMON SHALL NOT BE LIABLE FOR ANY DIRECT,
        INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
        INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL,
        USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF CHANGEMON HAS BEEN ADVISED
        OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE USE OR THE
        INABILITY TO USE THE SERVICE; (ii) THE COST OF PROCUREMENT OF SUBSTITUTE
        GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR
        SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS
        ENTERED INTO THROUGH OR FROM THE SERVICE; (iii) UNAUTHORIZED ACCESS TO
        OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (iv) STATEMENTS OR CONDUCT
        OF ANY THIRD PARTY ON THE SERVICE; OR (v) ANY OTHER MATTER RELATING TO
        THE SERVICE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN
        WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
        CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS OF
        DISCLAIMER OF WARRANTIES SECTION AND LIMITATION OF LIABILITY SECTION MAY
        NOT APPLY TO YOU.
      </p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style>
footer {
  background-color: rgb(51, 47, 47);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 2rem;
}

footer > div {
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.4);
}
</style>
