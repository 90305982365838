import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Monitor from "../views/Monitor.vue";
import MyMonitors from "../views/MyMonitors.vue";
import PayPalExecute from "../views/PayPalExecute.vue";
import ContactUs from "../views/ContactUs.vue";
import Help from "../views/Help.vue";
import ChangeEmail from "../views/ChangeEmail.vue";
import ImportMonitors from "../views/ImportMonitors.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/monitor/:id/:key",
      name: "monitor",
      component: Monitor,
      props: true,
    },
    {
      path: "/my-monitors/:key?/:secret?",
      name: "my-monitors",
      component: MyMonitors,
      props: true,
    },
    {
      path: "/paypal/execute",
      name: "paypal",
      component: PayPalExecute,
      props: true,
    },
    {
      path: "/contact-us",
      name: "contact-us",
      component: ContactUs,
      props: true,
    },
    {
      path: "/help",
      name: "help",
      component: Help,
      props: true,
    },
    {
      path: "/change-email",
      name: "change-email",
      component: ChangeEmail,
      props: true,
    },
    {
      path: "/imprt",
      name: "import-monitors",
      component: ImportMonitors,
      props: true,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
