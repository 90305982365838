export class State {
  static _ViewData = null;
  static _ScrollPosition = 0;
  static _FilterTxt = null;

  static get ViewData() {
    return this._ViewData;
  }

  static set ViewData(Data) {
    this._ViewData = Data;
  }

  static get ScrollPosition() {
    return this._ScrollPosition;
  }

  static set ScrollPosition(Value) {
    this._ScrollPosition = Value;
  }

  static get FilterText() {
    return this._FilterTxt;
  }

  static set FilterText(Value) {
    this._FilterTxt = Value;
  }
}
