<template>
  <div id="master-container" class="shadow">
    <div id="edit-form">
      <h1 class="cont"><i class="fas fa-download"></i> Import CSV</h1>

      <form class="shadow" style="margin-top: 1rem">
        <div v-if="!inProgress">
          <div>
            <label for="Message"
              >CSV Content [Title,URL,Type,Xpath,Interval]</label
            >
            <textarea
              class="msg"
              name="Content"
              v-model="csvContent"
              placeholder="Paste CSV content here..."
            ></textarea>
          </div>
          <div class="btn">
            <button id="save-monitor" @click.prevent="importMonitors">
              <i class="fas fa-download"></i>
              IMPORT MONITORS
            </button>
          </div>
        </div>
        <div>
          <ul id="imp-mons">
            <li v-for="(m, i) in monitors" :key="i">
              <div>
                <b>{{ monitors.length - i }}</b
                ><span v-if="m.Status">{{ m.Status }}</span> {{ m.Title }} -
                {{ m.URL }}
              </div>
              <div v-if="m.ErrorMsg">Error: {{ m.ErrorMsg }}</div>
              <spinner v-if="m.Status === ' CREATING: '" />
            </li>
          </ul>
          <p v-if="isComplete">IMPORT COMPLETE!!!</p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API } from "../constants.js";
import Spinner from "../cmp/Spinner";

export default {
  props: ["user"],

  components: { Spinner },

  data() {
    return {
      csvContent: "",
      inProgress: false,
      monitors: [],
      isComplete: false,
    };
  },

  methods: {
    importMonitors() {
      this.inProgress = true;
      this.csvContent.split("\n").forEach((line) => {
        if (!line) {
          return;
        }
        let cols = line.split(",");
        this.monitors.push({
          MonitorID: 0,
          Title: cols[0].trim(),
          URL: cols[1].trim(),
          Type: cols[2].trim(),
          TypeValue: cols[3].trim(),
          Interval: cols[4].trim(),
          Email: this.user.email,
          IsImport: 1,
          Status: "",
        });
      });

      let doPost = (mon) => {
        if (!mon) {
          this.isComplete = true;
          return;
        }
        mon.Status = " CREATING: ";
        axios
          .post(API + "monitor/save", mon)
          .then((res) => {
            let m = res.data;

            if (m.MonitorID === 0 && m.ValidationErrors) {
              this.csvContent = "";
              this.monitors.forEach((monitor) => {
                let line = "";
                line = `${monitor.Title},${monitor.URL},${monitor.Type},${monitor.TypeValue},${monitor.Interval}\n`;
                this.csvContent += line;
              });

              alert(
                "There was an error importing one of the monitors. Operation Aborted!"
              );
              //console.log(m);

              this.inProgress = false;
              this.monitors = [];
              return;
            }

            this.monitors.shift();
            doPost(this.monitors[0]);
          })
          .catch((err) => {
            alert(
              "Sorry! Something went worng...\n\nPlease try again...\n\n HINT: " +
                err.message
            );
          });
      };

      doPost(this.monitors[0]);
    },
  },
};
</script>

<style>
#imp-mons {
  list-style: none;
}

#imp-mons b {
  padding-right: 1rem;
}
</style>
