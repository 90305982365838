<template>
  <div id="master-container" class="shadow">
    <div class="login-wrapper" v-if="!viewData.LoginSuccessful && !inProgress">
      <div id="login" class="shadow">
        <h1>
          <i class="fas fa-user-lock" style="font-size: 3rem"></i> Sign In
        </h1>
        <p>
          Please sign in to access your monitors. Enter your email address to
          receive a link to authenticate yourself.
        </p>
        <input type="text" name="Email" v-model="emailAddress" />
        <div class="btn-wrapper">
          <button
            class="send-button"
            :disabled="isSending || emailSent"
            @click.prevent="sendLink"
          >
            <i v-if="!isSending && !emailSent" class="fas fa-sign-in-alt"></i>
            <i v-if="isSending" class="fas fa-spinner fa-pulse"></i>
            {{ sendBtnTxt }}
          </button>
        </div>
      </div>
    </div>

    <div class="wrapper" v-if="viewData.LoginSuccessful">
      <div class="subs shadow">
        <h2><i class="fas fa-credit-card"></i> My Subscription</h2>
        <p>
          <b>Status:</b>
          <span
            v-if="!inProgress"
            :class="{
              red: viewData.Subscription.IsExpired,
              green: !viewData.Subscription.IsExpired,
            }"
          >
            {{ viewData.Subscription.IsExpired ? "EXPIRED" : "VALID" }}</span
          >
        </p>
        <p class="identifier">
          <b>Valid Until:</b>
          {{ viewData.Subscription.PaidUntil | ToLocalDate }}
        </p>
        <p class="identifier">
          <b>Remaining Quota:</b>
          {{ this.remainingQuota }}
        </p>
        <p class="identifier">
          <b>Identifier:</b>
          {{ viewData.Subscription.UserIdentifier }}
        </p>

        <h2>
          <i class="fas fa-sync-alt" style="font-size: 1.9rem"></i> Renewal
        </h2>
        <p class="notice" v-if="!viewData.Subscription.IsExpired">
          You can extend the validity of your subscription before it expires by
          paying for further months.
        </p>
        <p class="notice" v-if="viewData.Subscription.IsExpired">
          You can renew your subscription by paying for up to 12 months in
          advance.
        </p>
        <p>
          <b>Months: </b>
          <select name="Months" v-model="renewalMonths">
            <option v-for="n in 12" :key="n" :value="n">{{ n }}</option>
          </select>
        </p>
        <p>
          <b>Total Amount: </b>
          USD {{ totalPurchaseAmt }}
        </p>
        <p class="pp-action">
          <button
            style="margin-top: 1rem; padding: 0.6rem"
            class="send-button"
            v-if="!ppInProgress"
            @click.prevent="getPayPalApproval"
          >
            <i class="fab fa-paypal" style="font-size: 1.5rem; color: #fff"></i
            >Make Payment
          </button>
          <span class="ppmsg" v-if="ppInProgress">
            One moment, sending you to PayPal...
          </span>
          <spinner v-if="ppInProgress"></spinner>
        </p>
      </div>

      <div class="mons shadow">
        <h2>
          <i class="fas fa-user-secret"></i> My Monitors ({{
            filteredMonitors ? filteredMonitors.length : 0
          }})
        </h2>

        <div class="actions">
          <span class="search-box">
            <span class="filters">
              <a
                v-if="viewData.ShowInactiveFilter"
                href="#"
                @click.prevent="filterInactive"
                title="Show only inactive monitors"
              >
                <i style="font-size: 15px" class="fas fa-times"></i>
              </a>

              <a
                v-if="viewData.ShowDisabledFilter"
                href="#"
                @click.prevent="filterDisabled"
                title="Show only disabled monitors"
              >
                <i class="fas fa-ban"></i>
              </a>

              <a
                v-if="viewData.ShowWarningFilter"
                href="#"
                @click.prevent="filterElementMissing"
                title="Show only element missing monitors"
              >
                <i
                  style="font-size: 12px"
                  class="fas fa-exclamation-triangle"
                ></i>
              </a>
            </span>

            <input
              :class="{ yellowbox: searchTerm ? true : false }"
              type="text"
              name="Search"
              v-model="searchTerm"
              placeholder="Search..."
              @click="searchTerm = ''"
            />
          </span>
          <span>
            <i class="fas fa-plus-square"></i>
            <router-link :to="{ name: 'monitor', params: { id: 0, key: 0 } }">
              New Monitor</router-link
            >
          </span>
          <span>
            <i class="fas fa-sign-out-alt"></i>
            <a href="#" @click.prevent="logUserOut"> Sign Out</a>
          </span>
        </div>

        <p
          v-if="
            filteredMonitors
              ? filteredMonitors.length == 0
              : true && !inProgress
          "
          style="text-align: center; margin: 2rem"
        >
          No monitors found...
        </p>

        <spinner v-if="inProgress" style="margin: 2rem 0" />

        <router-link
          class="mon"
          v-for="m in filteredMonitors"
          :key="m.MonitorID"
          :to="{ name: 'monitor', params: { id: m.MonitorID, key: m.Key } }"
        >
          <div>
            <div class="title">
              {{ m.Title }}
            </div>
            <div class="upd-time">
              <b>Checked On:</b> {{ m.LastUpdated | ToLocalDate }}
            </div>
            <div class="stat">
              <b>Status:</b>
              <i
                v-if="!m.IsEnabled && m.DisableReason === 8"
                class="fas fa-ban red"
                style="font-size: 1rem"
                title="Disabled"
              ></i>
              <i
                v-else-if="m.WarnNonExistentElement"
                class="fas fa-exclamation-triangle orange"
                style="font-size: 1rem"
                title="Non-existent element"
              ></i>
              <i
                v-else-if="!m.IsEnabled && m.DisableReason !== 8"
                class="fas fa-times red"
                title="Inactive"
              ></i>
              <i v-else class="fas fa-check green" title="Active"></i>
            </div>
          </div>
        </router-link>

        <div class="actions">
          <span>
            <i class="fas fa-at"></i>
            <router-link :to="{ name: 'change-email' }">
              Change Email</router-link
            >
          </span>
          <span>
            <i class="fas fa-file-export"></i>
            <a :href="exportLink" target="_blank"> Export To CSV</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API } from "../constants.js";
import Spinner from "../cmp/Spinner";
import { State } from "../state.js";

export default {
  name: "my-monitors",

  components: { Spinner },

  props: ["user"],

  data() {
    return {
      viewData: {},
      inProgress: false,
      isSending: false,
      emailSent: false,
      emailAddress: "",
      renewalMonths: 1,
      ppInProgress: false,
      searchTerm: "",
      currentFilter: "",
      showBlinky: false,
      exportLink:
        API +
        `my-monitors/export?userkey=${
          this.user ? this.user.key : ""
        }&usersecret=${this.user ? this.user.secret : ""}`,
    };
  },

  beforeDestroy() {
    State.ScrollPosition = window.scrollY;
    State.FilterText = this.searchTerm;
  },

  created() {
    var key = this.$route.params.key;
    var secret = this.$route.params.secret;

    if (key && secret) {
      this.$events.emit("set-user", {
        key: key,
        secret: secret,
        email: "",
      });
    } else if (this.user) {
      key = this.user.key;
      secret = this.user.secret;
    } else {
      return;
    }

    if (key && secret) {
      this.viewData = {};
      this.viewData.LoginSuccessful = true;
      this.viewData.Subscription = {};

      if (State.ViewData) {
        this.inProgress = true;
        setTimeout(() => {
          this.viewData = State.ViewData;
          this.inProgress = false;
          this.searchTerm = State.FilterText;
          setTimeout(() => {
            window.scrollTo(0, State.ScrollPosition);
          }, 100);
        }, 100);
      } else {
        this.inProgress = true;
        axios
          .get(API + `my-monitors/load?key=${key}&secret=${secret}`)
          .then((res) => {
            State.ViewData = res.data;
            this.viewData = State.ViewData;
            this.inProgress = false;
            if (this.viewData.EmailAddress && this.viewData.LoginSuccessful) {
              this.$events.emit("set-user", {
                key: key,
                secret: secret,
                email: this.viewData.EmailAddress,
              });
            } else {
              this.$events.emit("remove-user");
            }
          })
          .catch((err) => {
            var msg = err.message;
            if (err.response) {
              msg = err.response.data.Message;
            }

            this.$alertify.alert(
              "Error",
              "Sorry! Something went worng... <p>Please try again...</p> HINT: " +
                msg
            );
            this.inProgress = false;
            this.viewData.LoginSuccessful = false;
          });
      }
    }
  },

  methods: {
    getPayPalApproval() {
      this.ppInProgress = true;
      axios
        .get(
          API +
            `paypal/get-approval?userkey=${this.user.key}&months=${this.renewalMonths}`
        )
        .then((res) => {
          if (res.data) {
            window.location.href = res.data;
          } else {
            this.$alertify.alert(
              "Error",
              "Sorry! Something went wrong... <p> Please try again...</p> HINT: " +
                "Invalid Paypal Approval Link Received..."
            );
            this.ppInProgress = false;
          }
        })
        .catch((err) => {
          this.$alertify.alert(
            "Error",
            "Sorry! Something went wrong...<p>Please try again...</p> HINT: " +
              err.response.data.Message
          );
          this.ppInProgress = false;
        });
    },
    logUserOut() {
      this.$events.emit("remove-user");
      this.viewData.LoginSuccessful = false;
      this.$router.push({ name: "my-monitors" });
    },
    sendLink() {
      if (this.emailAddress.length > 4) {
        this.isSending = true;
        axios
          .post(API + "my-monitors/authenticate", this.emailAddress)
          .then(() => {
            this.isSending = false;
            this.emailSent = true;
            this.$alertify.alert(
              "<span style='color:green'>Success!</span>",
              "Email Sent... Please check your email in a few minutes. <p>If there's no email, please check your spam folder.</p>Or try sending the link again..."
            );
          })
          .catch((err) => {
            this.$alertify.alert(
              "Error",
              "Sorry! Something went wrong...<p>Please try again...</p> HINT: " +
                err.response.data.Message
            );
            this.isSending = false;
          });
      }
    },
    filterInactive() {
      if (this.currentFilter == "inactive") {
        this.currentFilter = "";
        this.searchTerm = "";
      } else {
        this.searchTerm = "[Inactive]";
      }
    },
    filterDisabled() {
      if (this.currentFilter == "disabled") {
        this.currentFilter = "";
        this.searchTerm = "";
      } else {
        this.searchTerm = "[Disabled]";
      }
    },
    filterElementMissing() {
      if (this.currentFilter == "warning") {
        this.currentFilter = "";
        this.searchTerm = "";
      } else {
        this.searchTerm = "[Warning]";
      }
    },
  },

  computed: {
    sendBtnTxt() {
      if (this.isSending) {
        return "Sending...";
      }
      if (this.emailSent) {
        return "Done!";
      }
      return "Send Link";
    },

    remainingQuota() {
      if (this.viewData.Monitors) {
        return 1000 - this.viewData.Monitors.length;
      }
      return "";
    },

    totalPurchaseAmt() {
      return (9.95 * this.renewalMonths).toFixed(2);
    },
    filteredMonitors() {
      if (!this.searchTerm) {
        return this.viewData.Monitors;
      } else {
        var data = this;
        switch (this.searchTerm) {
          case "[Inactive]":
            data.currentFilter = "inactive";
            return this.viewData.Monitors.filter(
              (mon) => mon.IsEnabled === false && mon.DisableReason !== 8
            );

          case "[Disabled]":
            data.currentFilter = "disabled";
            return this.viewData.Monitors.filter(
              (mon) => mon.IsEnabled === false && mon.DisableReason === 8
            );

          case "[Warning]":
            data.currentFilter = "warning";
            return this.viewData.Monitors.filter(
              (mon) => mon.WarnNonExistentElement === true
            );

          default:
            data.currentFilter = "";
            return this.viewData.Monitors.filter((mon) =>
              mon.Title.toLowerCase().includes(this.searchTerm.toLowerCase())
            );
        }
      }
    },
  },
};
</script>

<style>
.red {
  color: red !important;
}

.green {
  color: rgb(37, 192, 37) !important;
}

.orange {
  color: rgb(241, 166, 27) !important;
}

#master-container {
  background-color: rgba(255, 255, 255, 0.76);
  border-radius: 7px;
  min-height: 50vh;
  padding: 0.5rem;
}

#login {
  padding: 1rem;
  border-radius: 7px;
  background-color: rgba(218, 218, 218, 0.582);
  max-width: 750px;
}

#login input {
  border: 1px solid rgb(199, 199, 199);
  background-color: rgb(250, 250, 250);
  border-radius: 0.5rem;
  padding: 0.8rem;
  display: block;
  min-width: 250px;
  margin: 1rem auto;
}

.login-wrapper {
  height: 100%;
  margin-top: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-wrapper {
  text-align: center;
}

.send-button {
  border: 1px solid grey;
  background-color: #1d8335;
  padding: 1rem;
  border-radius: 1rem;
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 1.2rem;
}

.send-button:hover {
  background-color: #33af50;
  cursor: pointer;
}

.send-button:disabled {
  background-color: rgb(99, 99, 99);
}
.send-button > i {
  margin-right: 1rem;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
}

.wrapper > .mons {
  flex: 10 0;
  border-radius: 7px;
  margin: 0.5rem;
  padding: 1rem;
}

.wrapper > .subs {
  flex: 1 0 250px;
  border-radius: 7px;
  margin: 0.5rem;
  padding: 1rem;
}

.mon > div {
  background-color: rgba(230, 230, 230, 0.521);
  margin: 0.7rem 0;
  padding: 0.5rem;
  border-radius: 7px;
  border: 1px solid rgba(230, 230, 230, 0.521);
}

.mon > div:hover {
  background-color: #fff;
  border: 1px dotted rgba(0, 0, 0, 0.171);
}

.mon:any-link {
  color: rgb(8, 12, 54);
}

.mons i {
  color: rgb(33, 102, 155);
}

.upd-time {
  font-size: 0.8rem;
}

.title {
  border-bottom: 1px solid rgba(138, 138, 138, 0.212);
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: rgb(52, 50, 155);
}

.stat {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
}

.stat i {
  font-size: 1.2rem;
}

.stat b {
  margin-right: 0.5rem;
}

.subs i {
  color: rgb(33, 102, 155);
}

.identifier {
  max-width: 310px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(119, 119, 119);
}

.identifier b {
  color: initial;
}

.actions {
  text-align: right;
  font-size: 0.84rem;
}

.actions a:any-link {
  color: rgb(8, 12, 54);
}

.actions span {
  margin-left: 1rem;
  min-width: 65px;
  display: inline-block;
}

.filters i:hover {
  color: rgb(240, 202, 80);
}

.actions a:hover {
  font-weight: bold;
}

.notice {
  font-size: 0.9rem;
  color: rgb(119, 119, 119);
  margin-bottom: 1rem;
}

.ppmsg {
  display: block;
  margin-top: 1rem;
}

.search-box i {
  margin-right: 10px;
}

.search-box input {
  border: 1px solid rgba(128, 128, 128, 0.308);
  border-radius: 5px;
  color: rgb(78, 78, 78);
  background-color: rgba(255, 255, 255, 0.76);
  height: 19px;
  width: 110px;
}

.yellowbox {
  background-color: #ffffd7 !important;
}

@media screen and (min-width: 450px) {
  .wrapper {
    flex-direction: row-reverse;
  }
  .wrapper > .mons {
    flex-basis: 330px;
  }

  .wrapper > .subs {
    max-height: 450px;
  }
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1024px) {
}
</style>
