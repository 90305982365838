<template>
  <header>
    <a href="/" id="logolink"
      ><img src="../assets/logo.png" id="logo" alt=""
    /></a>
    <span id="brand-name">ChangeMon v2</span>
    <ul id="menu">
      <li>
        <router-link to="/">Home</router-link>
      </li>
      <li>
        <router-link to="/my-monitors" active-class="active"
          >My Monitors</router-link
        >
      </li>
      <li>
        <router-link to="/help" active-class="active">Help</router-link>
      </li>
      <li>
        <router-link to="/contact-us" active-class="active"
          >Contact Us</router-link
        >
      </li>
    </ul>
  </header>
</template>

<script>
export default {};
</script>

<style>
a.router-link-exact-active,
a.active {
  text-decoration: underline;
  color: black !important;
}

header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 6px solid #71095a98;
  top: 0;
  position: sticky;
  background: #9fbaff;
  border-radius: 10px 10px 0 0;
}

#logo {
  width: 100px;
  height: 100px;
  max-width: 100px;
  align-self: center;
  flex: 0;
  margin-left: 0.5rem;
  padding-left: 1rem;
}

#logolink {
  height: 96px;
}

#brand-name {
  font-family: "Quattrocento", serif;
  font-weight: bolder;
  font-size: 1.5rem;
  align-self: center;
  flex: 1 0 180px;
}

#menu {
  display: flex;
  flex-direction: column;
  margin: 0.2rem;
  font-weight: 600;
  font-size: 1rem;
}

#menu > li {
  list-style: none;
  background-color: rgba(94, 94, 94, 0.281);
  flex: 1 0;
  margin: 0.1rem;
  padding: 0 0.2rem;
}

@media screen and (min-width: 450px) {
  #menu {
    flex-direction: row;
    flex: 1 0;
    justify-content: center;
    max-height: 30px;
    min-width: 435px;
    align-self: center;
  }
  #menu > li {
    text-align: center;
    background-color: initial;
    border-right: 1px solid rgb(156, 156, 156);
  }
  #menu > li:last-child {
    border-right: none;
  }
  #brand-name {
    flex: 0 0 180px;
  }
}

@media screen and (min-width: 768px) {
  #brand-name {
    flex: 1 0 180px;
  }
}

@media screen and (min-width: 1024px) {
}
</style>
